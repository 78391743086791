import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesComponent } from './components/cookies/cookies.component';
import { InvalidComponent } from './components/invalid/invalid.component';
import { TariffComponent } from './components/tariff/tariff.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CoreRoutingModule } from './core-routing.module';
import { AppCommonModule } from './modules/common/app-common.module';

@NgModule({
  imports: [AppCommonModule, CoreRoutingModule, TranslateModule],
  declarations: [
    CookiesComponent,
    WelcomeComponent,
    InvalidComponent,
    TariffComponent,
  ],
  providers: [CookieService],
  exports: [
    CookiesComponent,
    WelcomeComponent,
    TariffComponent,
    InvalidComponent,
  ],
})
export class CoreModule {}
