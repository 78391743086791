/* eslint-disable @typescript-eslint/naming-convention */

import { session } from '../services/storage/storage';

const DEFAULT_CONFIG_VALUE = 'GB';

const contextConfig = {
  DE: {
    host: 'charge.ubitricity.com',
    supportedLanguages: ['de', 'en'],
  },
  FR: {
    host: 'charge.ubitricity.com',
    supportedLanguages: ['fr', 'en'],
  },
  GB: {
    host: 'mySimpleSocket.com',
    supportedLanguages: ['en', 'de', 'fr'],
  },
};

const currentContext = (): string => {
  const sessionContext = session.getItem('DA_CONTEXT');

  if (sessionContext) {
    return sessionContext;
  }

  if (/charge(-staging)?.ubitricity.com/.test(window.location.host)) {
    // Fallback to DE
    return 'DE';
  }

  return DEFAULT_CONFIG_VALUE;
};

export const CurrentHash = (): string => {
  try {
    const hashTab = document
      .querySelector('script[type="module"][src*="main"]')
      ['src'].split('.');
    const hashTabLength = hashTab.length;
    return hashTab[hashTabLength - 2];
  } catch (E) {
    return new Date().toISOString();
  }
};

export const MasterContext = (): string => currentContext() + '_CONTEXT';

export const MasterContextPrefix = (): string =>
  contextConfig[currentContext()].host;

export const masterContextSupportedLangs = (): string[] =>
  contextConfig[currentContext()].supportedLanguages;
