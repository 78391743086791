import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from '../environments/environment';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpLoaderFactory } from './core/modules/common/http-loader.factory';
import { FrameModule } from './frame/frame.module';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');

const loadAppConfig = (appConfig: AppConfigService) => (): Promise<any> =>
  appConfig.loadConfig();

Sentry.init({
  dsn: 'https://38d094a0c6984f4ab70927006fe9aa5f@o236290.ingest.sentry.io/1876333',
  environment: environment.SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  tracesSampleRate: 0.1,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    FrameModule,
    FormsModule,
    BrowserAnimationsModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
