import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Device} from '../../dto/device';
import {Observable} from 'rxjs';
import {StripeToken} from '../../dto/token.model';

@Injectable({
  providedIn: 'root',
})
export class DirectAccessService {
  constructor(private _http: HttpClient) {}

  private _directAccessPath: string = environment.BASE_URL + environment.PATH;

  /**
   * Fetch device state every
   * 5 seconds from DA backend
   *
   * @param deviceData
   *
   */
  public getState(deviceData: Device): Observable<any> {
    return this._http.get(
      this.getStateUrl(deviceData.chargePointId, deviceData.evseOcppIndex)
    );
  }

  public getDeviceData(qrCodeId: string): Observable<any> {
    return this._http.get(this.getDeviceUrl(qrCodeId));
  }

  /**
   * Send token to backend
   * for creating charge request
   * to stripe
   *
   */
  public saveToken(
    token: StripeToken,
    locale: string,
    notificationsEnabled: boolean,
    ssoId: string,
    customerId?: string
  ) {
    return this._http.put(
      this._directAccessPath + 'stripe-tokens/' + ssoId,
      {
        tokenId: token.payment_method,
        qrCodeId: token.qrCodeId,
        clientEmail: token.email,
        customerId: customerId ? customerId : null,
        persistCustomer: token.persistCustomer,
      },
      {
        responseType: 'text',
        params: new HttpParams()
          .set('locale', locale)
          .set('notificationsEnabled', notificationsEnabled ? 'true' : 'false'),
      }
    );
  }

  public getStripeCustomer(
    customerId: string,
    countryCode: string,
    ssoId: string
  ) {
    const params = new HttpParams()
      .set('countryCode', countryCode)
      .set('ssoId', ssoId);
    return this._http.get(
      `${this._directAccessPath}stripe-tokens/${customerId}`,
      { params: params }
    );
  }

  public deleteStripeCustomer(
    customerId: string,
    countryCode: string,
    ssoId: string
  ) {
    const params = new HttpParams()
      .set('countryCode', countryCode)
      .set('ssoId', ssoId);
    return this._http.delete(
      `${this._directAccessPath}stripe-tokens/${customerId}`,
      { params: params }
    );
  }

  /**
   * Initiate charging process
   *
   */
  public startCharging(ssoId: string, hoursParams: string) {
    const url = `${this._directAccessPath}charge-authorization/${ssoId}${hoursParams}`;
    return this._http.put(url, {});
  }

  public authorizeCharging(
    ssoId: string,
    hoursParams: string,
    parameters: AuthorizationDto
  ) {
    const url = `${this._directAccessPath}charge-authorization/${ssoId}${hoursParams}`;
    return this._http.post(url, parameters);
  }

  public createSetupIntent(
    countryCode: string,
    ssoId: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('countryCode', countryCode);
    params = params.append('ssoId', ssoId);

    return this._http.get(
      this._directAccessPath + 'stripe-tokens/setup-intent',
      { params: params }
    );
  }

  private getDeviceUrl = (qrCodeId: string): string =>
    this._directAccessPath + 'sso-data/' + qrCodeId;

  private getStateUrl = (ssoId: string, evseOcppIndex): string =>
    this._directAccessPath +
    'sso-data/' +
    ssoId +
    '/state?evseOcppIndex=' +
    evseOcppIndex;

  public sendSurveyMobileAppResearchAgreement(
    customerEmail: string
  ): Observable<any> {
    const url = `${this._directAccessPath}survey/mobile-app`;
    const params = new HttpParams().set('email', customerEmail);
    return this._http.post(url, params);
  }
}

export class AuthorizationDto {
  constructor(
    paymentMethod: string,
    customerId: string,
    persistCustomer: boolean,
    qrCodeId: string,
    clientEmail: string,
    locale: string,
    notificationsEnabled: boolean,
    offPeakChargingOpted: boolean,
    transactionIdTag: string
  ) {}
}
