import { CountryCode } from '../../dto/device';
import { Device } from 'src/app/core/dto/device';
import { SmartChargingService } from '../../services/smart-charging/smart-charging.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { MasterContext } from '../../config/masterContext.config';
import {
  DirectAccessObservationDto,
  Fee,
  FeeType,
  State,
  TariffType,
} from '../../dto/device';
import { ScheduledCharging } from '../../dto/scheduledCharging';
import { DeviceDataService } from '../../services/device-data/device-data.service';
import { PaymentHelperService } from '../../services/payment/payment-helper.service';
import { ScheduledChargingService } from '../../services/scheduled-charging/scheduled-charging.service';
import { SplitService } from '../../services/split/split.service';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import * as moment from 'moment-timezone';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('true', style({height: '0', visibility: 'hidden'})),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  public DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND';

  public FeeType = FeeType;

  public deviceData: any;
  public stateObservation: DirectAccessObservationDto;
  public currentHours: number;
  public currentLang: string;

  public ScheduledCharging = ScheduledCharging;
  public scheduledChargingEnabled: boolean;
  public scheduledChargingTime;

  public tariffPostfix: string = 'default';

  public showNotificationPopup: boolean;
  public overstayFeeModal = false;

  public masterContext = MasterContext();

  public isOffPeakTariff: boolean;
  public isPeakTariff: boolean;
  public shouldShowOffPeakOption: boolean;
  public showOffPeakMessage: boolean;
  public showSmartChargingInfo: boolean;
  public muteOffPeakMessage: boolean;
  public collapsed: boolean[] = [false, true];
  public showOnOffPeakWindow: boolean;
  public showSkipPeakPopup: boolean;
  public showPeakConfirmationPopup: boolean;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: DeviceDataService,
    private splitService: SplitService,
    private translate: TranslateService,
    private paymentHelper: PaymentHelperService,
    private scheduledChargingService: ScheduledChargingService,
    private cookieService: CookieService,
    private smartChargingService: SmartChargingService
  ) {
    this.scheduledChargingService.scheduledChargingEnabled
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (scheduledChargingEnabled) =>
          (this.scheduledChargingEnabled = scheduledChargingEnabled)
      );
    this.scheduledChargingService.scheduledChargingTime
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (scheduledChargingTime) =>
          (this.scheduledChargingTime = scheduledChargingTime)
      );
  }

  ngOnInit(): void {
    const qrCode = this.route.snapshot.paramMap.get('id');
    this._getDevice(qrCode);

    this.service.deviceState.subscribe(
      (state) => (this.stateObservation = state)
    );
    console.log('stateObservation - ' + this.stateObservation);
    this.translate.onLangChange.subscribe((lang) => {
      this.service.setCurrentLang(lang.lang);
    });

    this.service.currentLang.subscribe((lang) => {
      this.currentLang = lang;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public hideNotificationPopup(): void {
    this.showNotificationPopup = false;
  }

  public isSmartChargingView(): boolean {
    return this.isOffPeakTariff && this.shouldShowOffPeakOption;
  }

  private async _getDevice(qrCode: string): Promise<void> {
    this.service.setQrCode(qrCode).pipe(
      map((deviceData) => {
        if (deviceData !== 'No device found') {
          deviceData.timezone = deviceData.timezone || 'UTC';

          this.deviceData = deviceData;
          this.service.setDeviceData(deviceData);
          this.service.getState(deviceData);
          this.shouldShowOffPeakOption = this.isInSmartChargingWindow(deviceData.timezone);
          this.showOnOffPeakWindow = this.isInOffPeakWindow(deviceData.timezone);
          this.isOffPeakTariff = !!this.deviceData && this.hasOffPeakTariff() && deviceData.smartChargingSupported;
          this.overstayFeeModal = !!this.getFee(FeeType.OVERSTAY_FEE);

          if (this.isOffPeakTariff && this.shouldShowOffPeakOption) {
            const infoMuted = this.cookieService.get('OPM');
            if (infoMuted !== '1') {
              this.showOffPeakMessage = true;
            }
          }
        }
        return deviceData;
      })
    ).subscribe(
      (device: Device) => {
        this.deviceData.tariff.tariffType = this.getTariffType(this.deviceData);
        this.isPeakTariff = !!this.deviceData && this.hasPeakTariff();

        this.tariffPostfix =
          (device && device.tariff && device.tariff.tariffType) || 'default';

        this.navigateTo(device.qrCodeId);

        this.checkScheduledCharging();

        this.runNotificationMechanismForDevice();
      },
      (error) => {
        this.deviceData = this.DEVICE_NOT_FOUND;
      }
    );
  }

  private wasDisplayedForDevice(deviceId) {
    return localStorage.getItem(deviceId);
  }

  private setDisplayedForDevice(deviceId) {
    localStorage.setItem(deviceId, new Date().toISOString());
  }

  private runNotificationMechanismForDevice() {
    // if (this.wasDisplayedForDevice(this.deviceData.chargePointId)) {
    //   return;
    // }
    const refreshInterval = setInterval(() => {
      if (!this.showNotificationPopup) {
        const isNotificationEnabled =
          this.splitService.isNotificationPopupEnabled(
            this.deviceData.cppContractNumber
          );
        if (isNotificationEnabled) {
          this.showNotificationPopup = isNotificationEnabled;
          this.setDisplayedForDevice(this.deviceData.chargePointId);
          clearInterval(refreshInterval);
        }
      }
    }, 100);
    setTimeout(() => {
      clearInterval(refreshInterval);
    }, 5000);
  }

  public setCurrentHours(hours: number): void {
    this.currentHours = hours;
    this.service.hours = hours;
  }

  public isHoursTariff(): boolean {
    return this.deviceData.tariff.tariffType === TariffType.HOURS;
  }

  public isHoursDisabled(): boolean {
    return this.isHoursTariff() && !this.currentHours;
  }

  public isDeviceDisabled(): boolean {
    return (
      this.stateObservation.directAccessState !== State.AVAILABLE &&
      this.stateObservation.directAccessState !== State.PLUGGED_IN
    );
  }

  public showSmartChargingInfoPopup(): void {
    this.showSmartChargingInfo = true;
  }

  public closeSmartChargingInfoPopup(): void {
    this.showSmartChargingInfo = false;
  }

  public isDisplayParkingStatusEnabled(): boolean {
    return this.splitService.isDisplayParkingStatusEnabled(
      this.deviceData.chargePointId
    );
  }

  public proceedToNextComponent(scheduleCharging = false): void {
    this.service.processEnded = false;
    if (scheduleCharging) {
      this.scheduledChargingService.setScheduledChargingPicked(true);
    } else {
      this.scheduledChargingService.setScheduledChargingPicked(false);
    }

    if (this.isPeakTariff) {
      this.showSkipPeakPopup = true;
    } else if (this.stateObservation.directAccessState === State.AVAILABLE && !this.deviceData.canOmitStatusCheckPage) {
      this.navigateTo('initialization');
    } else {
      this.proceedToPaymentComponent();
    }
  }

  public showPeakChargingConfirmationPopup() {
    this.showSkipPeakPopup = false;
    this.showPeakConfirmationPopup = true;
  }

  public proceedToNextComponentWithSmartCharging(enabled: boolean = false): void {
    this.showSkipPeakPopup = false;
    this.showPeakConfirmationPopup = false;

    this.service.processEnded = false;
    this.smartChargingService.setSmartCharging(enabled);
    if (this.stateObservation.directAccessState === State.AVAILABLE
      && !this.deviceData.canOmitStatusCheckPage) {
      this.navigateTo('initialization');
    } else {
      this.proceedToPaymentComponent();
    }
  }

  private checkScheduledCharging() {
    const refreshInterval = setInterval(
      () =>
        this.scheduledChargingService.isScheduledChargingEnabled(
          this.deviceData.address.countryCode,
          this.deviceData.chargePointId
        ),
      100
    );
    setTimeout(() => {
      clearInterval(refreshInterval);
    }, 5000);
  }

  private proceedToPaymentComponent(): void {
    if (this.paymentHelper.isSkipPayment()) {
      this.navigateTo('payment');
    } else {
      this.navigateTo('stripe');
    }
  }

  public navigateTo(destination): void {
    this.service.setCurrentComponent(destination);
    this.router.navigate([`${destination}`, this.deviceData.transactionIdTag]);
  }

  public getFee(feeType: FeeType): number {
    const fee: Fee = this.findFee(feeType);
    if (fee && fee.directAccessCharging) {
      return fee.userPricePerUnit;
    } else {
      return null;
    }
  }

  public getFeeInMinorUnits(feeType: FeeType): number {
    const fee: Fee = this.findFee(feeType);
    if (fee && fee.directAccessCharging) {
      if (fee.userPricePerUnit < 1) {
        return fee.userPricePerUnit * 100;
      }
      return fee.userPricePerUnit;
    } else {
      return null;
    }
  }

  public getWorkFee(): number {
    const workFee: Fee = this.findFee(FeeType.WORK_FEE);
    const providerWorkFee: Fee = this.findFee(FeeType.PROVIDER_WORK_FEE);
    let total: number;

    total = workFee && workFee.directAccessCharging ? workFee.userPricePerUnit : 0;
    total += providerWorkFee && providerWorkFee.directAccessCharging
      ? providerWorkFee.userPricePerUnit : 0;

    return total;
  }

  public getOverstayFreePeriod(feeType = FeeType.PROVIDER_OVERSTAY_FEE): number {
    const fee: Fee = this.findFee(feeType);
    if (fee) {
      return fee.freeInterval;
    }
  }

  public showPluginFee(): boolean {
    const fee = this.findFee(FeeType.PLUG_IN_FEE);
    return fee !== (null || undefined) && fee.directAccessCharging;
  }

  public showMinimum(): boolean {
    if (this.deviceData) {
      if (this.isHoursTariff()) {
        return false;
      }
      const pifAmount = this.getFee(FeeType.PLUG_IN_FEE);
      const workFeeAmount = this.getFee(FeeType.WORK_FEE);

      if (!pifAmount && workFeeAmount > 0) {
        return true;
      }
    }
  }

  public isDeviceCurrency(currency: string): boolean {
    return this.deviceData.tariff.currency === currency;
  }

  public isUKDevice(): boolean {
    return this.deviceData.address.countryCode == CountryCode.GB;
  }

  public isDEDevice(): boolean {
    return this.deviceData.address.countryCode == CountryCode.DE;
  }

  private findFee(feeType: FeeType): Fee {
    return this.deviceData.tariff?.fees.find((fee) => fee.type === feeType);
  }

  private getTariffType(deviceData: any): TariffType {
    if (deviceData.tariff.fees) {
      const fee = this.findFee(FeeType.WORK_FEE);
      return this.parseFeeType(fee?.billingUnit);
    } else {
      return deviceData.tariff.tariffType;
    }
  }

  private parseFeeType(fee: string) {
    switch (fee) {
      case 'HH':
        return TariffType.HOURS;
      case 'KWH':
        return TariffType.KWH;
      case null:
        return TariffType.EVENT;
      default:
        return TariffType.KWH;
    }
  }

  private hasOffPeakTariff() {
    return !!this.findFee(FeeType.OFF_PEAK_WORK_FEE);
  }

  private hasPeakTariff() {
    return !!this.findFee(FeeType.PEAK_WORK_FEE);
  }

  private hasWorkFeeTariff() {
    return !!this.findFee(FeeType.WORK_FEE);
  }

  private isInSmartChargingWindow(deviceTimezone: string): boolean {
    const fee = this.findFee(FeeType.OFF_PEAK_WORK_FEE);
    if (!fee) {
      return false;
    }
    const period = this.getOffPeakPeriod(deviceTimezone);
    const now = moment.utc(this.deviceData.serverTime).tz(deviceTimezone);

    return period.available.isBefore(now) && now.isBefore(period.start);
  }

  private isInOffPeakWindow(deviceTimezone: string): boolean {
    const fee = this.findFee(FeeType.OFF_PEAK_WORK_FEE);
    if (!fee) {
      return false;
    }
    const period = this.getOffPeakPeriod(deviceTimezone);
    const now = moment.utc(this.deviceData.serverTime).tz(deviceTimezone);

    return (period.available.isBefore(now) && now.isBefore(period.start))
      || (period.start.isBefore(now) && now.isBefore(period.end));
  }

  public showOffPeakInfo() {
    this.showOffPeakMessage = true;
    this.muteOffPeakMessage = false;
    this.cookieService.delete('OPM');
  }

  public hideOffPeakInfo() {
    this.showOffPeakMessage = false;
    if (this.muteOffPeakMessage) {
      this.cookieService.set(
        'OPM',
        '1',
        Date.now() + 365 * 24 * 60 * 60 * 1000,
        null,
        null,
        true,
        'Strict'
      );
    }
  }

  public getOffPeakPeriod(deviceTimezone?: string) {
    const fee = this.findFee(FeeType.OFF_PEAK_WORK_FEE);

    const available = fee?.smartChargingOptionAvailableFrom || [18, 0];
    const start = fee?.offPeakPeriodStart || [0, 0];
    const end = fee?.offPeakPeriodEnd || [7, 0];

    const startDate = deviceTimezone
        ? moment.utc(this.deviceData.serverTime).tz(deviceTimezone).hour(start[0]).minute(start[1]).second(0)
        : moment().hour(start[0]).minute(start[1]).second(0);
    const endDate = deviceTimezone
      ? moment.utc(this.deviceData.serverTime).tz(deviceTimezone).hour(end[0]).minute(end[1]).second(0)
      : moment().hour(end[0]).minute(end[1]).second(0);
    if (start[0] < available[0]) {
      startDate.add(1, 'd');
    }
    if (end[0] < available[0]) {
      endDate.add(1, 'd');
    }

    return {
      available: deviceTimezone
        ? moment.utc(this.deviceData.serverTime).tz(deviceTimezone).hour(available[0]).minute(available[1]).second(0)
        : moment().hour(available[0]).minute(available[1]).second(0),
      start: startDate,
      end: endDate
    };
  }

  public getPeakPeriod(deviceTimezone?: string) {
    const fee = this.findFee(FeeType.PEAK_WORK_FEE);

    const start = fee?.peakPeriodStart || [13, 0];
    const end = fee?.peakPeriodEnd || [17, 0];

    return {
      start: deviceTimezone
        ? moment.utc(this.deviceData.serverTime).tz(deviceTimezone).hour(start[0]).minute(start[1]).second(0)
        : moment().hour(start[0]).minute(start[1]).second(0),
      end: deviceTimezone
        ? moment.utc(this.deviceData.serverTime).tz(deviceTimezone).hour(end[0]).minute(end[1]).second(0)
        : moment().hour(end[0]).minute(end[1]).second(0)
    };
  }

  toggle(idx) {
    this.collapsed[idx] = !this.collapsed[idx];
  }

  protected readonly Math = Math;
}
