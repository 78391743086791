<div id="sidenav" class="sidenav" [ngClass]="{ 'sidenav--open': isMenuOpen }">
  <span
    id="sidenav_SPAN_close"
    (click)="close()"
    class="sidenav__icon sidenav__icon--close material-icons"
    >close</span
  >

  <div class="sidenav__content section">
    <div class="container">
      <ng-container *ngIf="qrCode">
        <hr class="sidenav__hr" />
        <span
          id="sidenav_SPAN_redirectQrCode"
          routerLink="/{{ qrCode }}"
          (click)="close()"
          >{{ 'sidenav.start' | translate }}</span
        >
      </ng-container>
      <ng-container *ngIf="!qrCode">
        <hr class="sidenav__hr" />
        <span
          id="sidenav_SPAN_redirectInvalid"
          routerLink="/invalid"
          (click)="close()"
          >{{ 'sidenav.start' | translate }}</span
        >
      </ng-container>

      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectContact"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'contact']"
        >{{ 'sidenav.one' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectQa"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'qa']"
        >{{ 'sidenav.two' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectSafety"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'safety']"
        >{{ 'sidenav.three' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectTermsAndConditions"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'termsAndConditions']"
        >{{ 'sidenav.four' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectPrivacy"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'privacy']"
        >{{ 'sidenav.five' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectImpressum"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'impressum']"
        >{{ 'sidenav.six' | translate }}</span
      >
      <hr class="sidenav__hr" />
      <span id="sidenav_SPAN_showCookieConsent" (click)="showCookieConsent()">{{
        'sidenav.seven' | translate
      }}</span>

      <hr class="sidenav__hr" />
      <span
        id="sidenav_SPAN_redirectCookiePolicy"
        (click)="close()"
        [routerLink]="['/sidenav-info', 'cookiePolicy']"
      >{{
        'sidenav.eight' | translate
      }}</span>

      <hr class="sidenav__hr sidenav__hr--space" />

      <ng-container *ngFor="let lang of supportedLanguages">
        <hr class="sidenav__hr" />
        <span
          [attr.id]="'sidenav_SPAN_changeLang-' + lang"
          (click)="changeLang(lang)"
        >
          {{ languageNames[lang] || lang }}
        </span>
      </ng-container>

      <hr class="sidenav__hr" />
    </div>
  </div>
</div>
