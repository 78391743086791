import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ScheduledCharging,
  ScheduledChargingTimeDisplayConfig,
  ScheduledChargingTimeSource,
} from '../../dto/scheduledCharging';
import { SplitService } from '../split/split.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledChargingService {
  private scheduledChargingEnabledSource = new BehaviorSubject<boolean>(false);
  public scheduledChargingEnabled =
    this.scheduledChargingEnabledSource.asObservable();

  private scheduledChargingTimeSource =
    new BehaviorSubject<ScheduledChargingTimeSource>(
      new ScheduledChargingTimeSource()
    );
  public scheduledChargingTime =
    this.scheduledChargingTimeSource.asObservable();

  private scheduledChargingPickedSource = new BehaviorSubject<boolean>(false);
  public scheduledChargingPicked =
    this.scheduledChargingPickedSource.asObservable();

  constructor(private splitService: SplitService) {}

  public isScheduledChargingEnabled(countryCode, id): void {
    var startTime = this.getScheduledChargingTime();
    if (startTime.startString === ScheduledCharging.Later) {
      this.scheduledChargingEnabledSource.next(
        this.splitService.isScheduledChargingEnabled(countryCode, id)
      );
      this.scheduledChargingTimeSource.next(startTime);
    }
  }

  private getScheduledChargingTime(): ScheduledChargingTimeSource {
    const startTime = new Date();
    startTime.setHours(0, 0);
    const endTime = new Date();
    endTime.setHours(7, 0);
    let startString: ScheduledCharging;
    if (new Date().getTime() > endTime.getTime()) {
      startTime.setDate(startTime.getDate() + 1);
      endTime.setDate(endTime.getDate() + 1);
      startString = ScheduledCharging.Later;
    } else {
      startString = ScheduledCharging.Now;
    }
    return {
      startTime,
      startTimeString: startTime.toLocaleString(
        'en-US',
        ScheduledChargingTimeDisplayConfig
      ),
      endTime,
      endTimeString: endTime.toLocaleString(
        'en-US',
        ScheduledChargingTimeDisplayConfig
      ),
      startString,
    };
  }

  public setScheduledChargingPicked(picked: boolean) {
    this.scheduledChargingPickedSource.next(picked);
  }
}
