import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { AbstractView } from 'src/app/core/components/abstract-view/AbstractView.component';
import { DeviceDataService } from 'src/app/core/services/device-data/device-data.service';

import { masterContextSupportedLangs } from 'src/app/core/config/masterContext.config';

declare global {
  interface Window {
    UC_UI: any;
  }
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends AbstractView {
  @Output()
  public onMenuClose: EventEmitter<any> = new EventEmitter<any>();
  public isMenuOpen: boolean = false;

  public qrCode: string;

  public supportedLanguages = masterContextSupportedLangs();
  public languageNames = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
  };

  constructor(
    private service: DeviceDataService,
    private translate: TranslateService,
    router: Router
  ) {
    super(router, service, translate);
  }

  @Input()
  set openSideNavBar(value: boolean) {
    if (value) {
      this.open();
    } else {
      this.close();
    }
  }

  public open(): void {
    this.isMenuOpen = true;
    this.qrCode = this.service.getQrCode();
  }

  public close(): void {
    this.isMenuOpen = false;
    setTimeout(() => {
      this.onMenuClose.emit({});
    }, 400);
  }

  public changeLang(lang: string): void {
    this.translate.use(lang);
    this.service.setCurrentLang(lang);
    this.close();
  }

  public showCookieConsent(): void {
    window.UC_UI.showFirstLayer();
    this.close();
  }
}
