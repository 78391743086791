import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvalidComponent } from './components/invalid/invalid.component';
import { DeviceSessionExists } from './guards/device-session-exists.guard';
import { PathLoadGuard } from './guards/path-load.guard';

const routes: Routes = [
  { path: 'invalid', component: InvalidComponent },
  {
    path: 'authentication',
    canLoad: [PathLoadGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'sidenav-info/:template',
    canLoad: [PathLoadGuard],
    loadChildren: () =>
      import('./modules/sidenav-info/sidenav-info.module').then(
        (m) => m.SidenavInfoModule
      ),
  },
  {
    path: 'initialization/:tag',
    canLoad: [PathLoadGuard],
    loadChildren: () =>
      import('./modules/initialization/initialization.module').then(
        (m) => m.InitializationModule
      ),
  },
  {
    path: 'smartcable',
    canLoad: [PathLoadGuard],
    loadChildren: () =>
      import('./modules/smart-cable/smart-cable.module').then(
        (m) => m.SmartCableModule
      ),
  },
  {
    path: 'stripe/:tag',
    canLoad: [PathLoadGuard],
    canActivate: [DeviceSessionExists],
    loadChildren: () =>
      import('./modules/stripe/stripe.module').then((m) => m.StripeModule),
  },
  {
    path: 'payment/:tag',
    canLoad: [PathLoadGuard],
    canActivate: [DeviceSessionExists],
    loadChildren: () =>
      import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'charging/:tag',
    canLoad: [PathLoadGuard],
    canActivate: [DeviceSessionExists],
    loadChildren: () =>
      import('./modules/charging-event/charging-event.module').then(
        (m) => m.ChargingEventModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
