import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import {
  Device,
  DirectAccessObservationDto,
  State,
  TariffType,
} from '../../dto/device';
import {
  AuthorizationDto,
  DirectAccessService,
} from '../direct-access/direct-access.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService {
  constructor(
    private _directAccessService: DirectAccessService,
  ) {}

  private qrCode: string;
  private _hours: number = 0;
  private _device: Device;

  private _processEnded = false;

  private deviceDataSource = new BehaviorSubject<any>('');
  public deviceData = this.deviceDataSource.asObservable();

  private deviceStateSource = new BehaviorSubject<DirectAccessObservationDto>(
    new DirectAccessObservationDto()
  );
  public deviceState = this.deviceStateSource.asObservable();

  private currentComponentSource = new BehaviorSubject<string>('');
  public currentComponent = this.currentComponentSource.asObservable();

  private currentLangSource = new BehaviorSubject<any>(null);
  public currentLang = this.currentLangSource.asObservable();

  public setCurrentLang(lang: string): void {
    this.currentLangSource.next(lang);
  }

  public setCurrentComponent(component: string): void {
    this.currentComponentSource.next(component);
  }

  public setDeviceData(deviceData: Device): void {
    this._device = deviceData;
    this.deviceDataSource.next(deviceData);
  }

  public setDeviceState(state: DirectAccessObservationDto): void {
    this.deviceStateSource.next(state);
  }

  public getQrCode = () => this.qrCode;

  public getSsoId = () => this._device.id;

  public setQrCode = (id: string): Observable<any> => {
    this.qrCode = id;
    return this._directAccessService.getDeviceData(id);
  };

  public getState(deviceData: Device): void {
    this._directAccessService.getState(deviceData).subscribe((state) => {
      state.directAccessState = this.handleStateResponse(state);
      this.setDeviceState(state);
    },(err)=>{
      console.info(err);
    });

    interval(5000)
      .pipe(
        switchMap(() => this._directAccessService.getState(deviceData)),
        map((res) => res)
      )
      .subscribe((state: DirectAccessObservationDto) => {
        state.directAccessState = this.handleStateResponse(state);
        this.setDeviceState(state);
      },(err)=>{
        console.info(err);
      });
  }

  public startCharging(): Observable<any> {
    const hoursParams = this.getHoursQueryParameters();
    return this._directAccessService.startCharging(
      this._device.chargePointId,
      hoursParams
    );
  }

  public authorizeCharging(parameters: AuthorizationDto): Observable<any> {
    const hoursParams = this.getHoursQueryParameters();
    return this._directAccessService.authorizeCharging(
      this._device.chargePointId,
      hoursParams,
      parameters
    );
  }

  /**
   * Returns query parameters for device with HOURS tariff.
   */
  public getHoursQueryParameters(): string {
    const milliseconds = this._hours * 60 * 60 * 1000; // hours in milliseconds
    let parameters = '';

    if (
      this._device &&
      this._device.tariff &&
      this._device.tariff.tariffType === TariffType.HOURS
    ) {
      parameters = `?duration=${milliseconds}`;
    }

    return parameters;
  }

  get hours(): number {
    return this._hours;
  }

  set hours(hours: number) {
    this._hours = hours;
  }

  get processEnded(): boolean {
    return this._processEnded;
  }

  set processEnded(value: boolean) {
    this._processEnded = value;
  }

  /**
   * Since the client doesnt
   * need to see all the various states,
   * we map to relevant ones.
   *
   */
  private handleStateResponse(state): State {
    switch (state.directAccessState) {
      case 'AVAILABLE':
        return State.AVAILABLE;
      case 'CHARGING':
        return State.OCCUPIED;
      case 'FAULTED':
        return State.OUT_OF_SERVICE;
      case 'FINISHING':
        return State.OCCUPIED;
      case 'MM_AVAILABLE':
        return State.AVAILABLE;
      case 'MM_CONNECTED':
        return State.OCCUPIED;
      case 'PREPARING':
        return State.PLUGGED_IN;
      case 'SUSPENDED_EV':
        return State.OCCUPIED;
      case 'SUSPENDED_EVSE':
        return State.OCCUPIED;
      case 'UNKNOWN':
        return State.OUT_OF_SERVICE;
      //  standard-ocpp-connector specific
      case 'RESERVED':
        return State.OCCUPIED;
      case 'UNAVAILABLE':
        return State.OUT_OF_SERVICE;
    }
  }
}
