import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isSideNavOpen: boolean;

  constructor() {
    this.isSideNavOpen = false;
  }

  ngOnInit() {}

  public openNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  public onMenuClose() {
    this.isSideNavOpen = false;
  }
}
