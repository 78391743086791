<section class="section">
  <div class="container">
    <ng-container *ngIf="deviceData?.qrCodeId">
      <h1 class="title is-4">
        {{ 'welcome.title.' + tariffPostfix | translate }}
      </h1>
      <h2 class="subtitle is-6">
        {{ 'welcome.subtitle' | translate }}
      </h2>

      <ng-container *ngIf="isSmartChargingView()">
        <div class="container">
          <div class="accordion-item">
            <h2 class="title is-6">
              {{ 'welcome.smart-charging.title' | translate }}
              <span
                class="expand-icon material-icons is-pulled-right"
                (click)="toggle(0)"
                >{{ collapsed[0] ? 'expand_more' : 'expand_less' }}</span
              >
            </h2>
            <div class="content collapsible" [@collapse]="collapsed[0]">
              <p>{{ 'welcome.smart-charging.line1' | translate }}</p>
              <p>{{ 'welcome.smart-charging.line2' | translate }}</p>
              <p [innerHTML]="'welcome.smart-charging.line3' | translate"></p>
              <p>
                {{ 'welcome.smart-charging.price.smart-part1' | translate }} ({{ getOffPeakPeriod().start | date: 'shortTime' }}-{{ getOffPeakPeriod().end | date: 'shortTime' }}{{ 'welcome.smart-charging.price.smart-part2' | translate }}):
                <span class="is-pulled-right">
                  {{ deviceData.tariff.currency | currency_to_symbol }}{{
                    getFee(FeeType.OFF_PEAK_WORK_FEE) | number: '1.2-3':currentLang
                  }}
                </span>
              </p>
              <button
                id="welcome_BUTTON_skipPeakWithSmartCharge"
                class="button"
                [disabled]="isDeviceDisabled()"
                (click)="proceedToNextComponentWithSmartCharging(true)"
              >
                {{ 'welcome.smart-charging.button.smart' | translate }}
              </button>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="title is-6">
              {{ 'welcome.smart-charging.peak-title' | translate }}
              <span
                id="WELCOME_SPAN_expandIcon"
                class="expand-icon material-icons is-pulled-right"
                (click)="toggle(1)"
                >{{ collapsed[1] ? 'expand_more' : 'expand_less' }}</span
              >
            </h2>
            <div
              class="content collapsible"
              [ngStyle]="{ height: collapsed[1] ? 0 : 'unset' }"
              [@collapse]="collapsed[1]"
            >
              <p>{{ 'welcome.smart-charging.peak-line1' | translate }}</p>
              <p>{{ 'welcome.smart-charging.peak-line2' | translate }}</p>
              <p [innerHTML]="'welcome.smart-charging.line3' | translate"></p>
              <p *ngIf="isPeakTariff">
                {{ 'welcome.smart-charging.price.peak' | translate }}:
                <span class="is-pulled-right">
                  {{ deviceData.tariff.currency | currency_to_symbol }}{{ getFee(FeeType.PEAK_WORK_FEE) | number: '1.2-3':currentLang
                  }}
                </span>
              </p>
              <p>
                {{ 'welcome.smart-charging.price.standard' | translate }}:
                <span class="is-pulled-right">
                  {{ deviceData.tariff.currency | currency_to_symbol }}{{ getFee(FeeType.WORK_FEE) | number: '1.2-3':currentLang
                  }}
                </span>
              </p>
              <button
                id="welcome_BUTTON_chargeAtFullPrice"
                class="button is-inverted"
                [disabled]="isDeviceDisabled()"
                (click)="proceedToNextComponentWithSmartCharging(false)"
              >
                {{ 'welcome.smart-charging.button.peak' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <hr />
      <p>
        {{ 'welcome.one.' + tariffPostfix | translate }}
        <span class="is-pulled-right" [ngSwitch]="deviceData.address.countryCode">
          <ng-container
            >{{
              deviceData.power | number: '1.0-1':currentLang
            }}
            kW</ng-container
          >
        </span>
      </p>
      <hr />
      <p>
        {{ 'welcome.three' | translate }}
        <span class="is-pulled-right" [ngSwitch]="masterContext">
          <ng-container *ngSwitchCase="'FR_CONTEXT'">{{
            deviceData.qrCodeId
          }}</ng-container>
          <ng-container *ngSwitchCase="'DE_CONTEXT'">{{
            deviceData.qrCodeId
          }}</ng-container>
          <ng-container *ngSwitchDefault>{{
            deviceData.chargePointId
          }}</ng-container>
        </span>
      </p>
      <hr />
      <p>
        {{ 'welcome.five' | translate }}
        <span class="is-pulled-right">{{
          'state.' + stateObservation.directAccessState | translate
        }}</span>
      </p>
      <hr />

      <ng-container
        *ngIf="
          isDisplayParkingStatusEnabled() && stateObservation.parkingStatus
        "
      >
        <p>
          {{ 'welcome.parkingStatus' | translate }}
          <span class="is-pulled-right">{{
            'state.' + (stateObservation.parkingStatus | uppercase) | translate
          }}</span>
        </p>
        <hr />
      </ng-container>

      <ng-container *ngIf="deviceData.tariff.name">
        <ng-container *ngIf="showPluginFee()">
          <p class="tariff__fee tariff__fee--plugin">
            {{ 'tariff.plugin.' + tariffPostfix | translate }}
            <sup *ngIf="!isUKDevice()">{{ showMinimum() ? '2,3' : '1,2' }}</sup>
            <span *ngIf="!isUKDevice()" class="is-pulled-right">
              {{ getFee(FeeType.PLUG_IN_FEE) | number: '1.2-3':currentLang
              }}{{ deviceData.tariff.currency | currency_to_symbol }}
            </span>
            <span *ngIf="isUKDevice()" class="is-pulled-right">
              {{ deviceData.tariff.currency | currency_to_symbol }}{{ getFee(FeeType.PLUG_IN_FEE) | number: '1.2-3':currentLang
              }}
            </span>
          </p>

          <hr />
        </ng-container>

        <ng-container *ngIf="!isSmartChargingView()">
          <p class="tariff__fee tariff__fee--work" >
            <ng-container *ngIf="hasWorkFeeTariff()">
              {{ 'tariff.price.' + tariffPostfix | translate }}
              <sup *ngIf="!isUKDevice()"
              >{{ showMinimum() ? '1,2,3' : '1,2'
              }}{{ getFee(FeeType.OVERSTAY_FEE) ? ',6' : '' }}</sup
            >
             <span *ngIf="!isUKDevice()" class="is-pulled-right">
               {{ getWorkFee() | number: '1.2-3':currentLang
               }}{{ deviceData.tariff.currency | currency_to_symbol }}
             </span>
             <span *ngIf="isUKDevice()" class="is-pulled-right">
               {{ deviceData.tariff.currency | currency_to_symbol }}{{ getWorkFee() | number: '1.2-3':currentLang
               }}
             </span>
            </ng-container>
          </p>
          <hr *ngIf="isOffPeakTariff" />
          <p class="tariff__fee tariff__fee--work">
            <ng-container *ngIf="isOffPeakTariff">
              {{ 'tariff.price.smart' | translate }} ({{
              getOffPeakPeriod().start | date: 'shortTime'
              }}-{{
              getOffPeakPeriod().end | date: 'shortTime'
              }})
              <sup *ngIf="!isUKDevice()"
              >{{ showMinimum() ? '1,2,3' : '1,2'
                }}{{ getFee(FeeType.OVERSTAY_FEE) ? ',6' : '' }},7</sup
              >
             <span *ngIf="!isUKDevice()" class="is-pulled-right">
               {{ getFee(FeeType.OFF_PEAK_WORK_FEE) | number: '1.2-3':currentLang
               }}{{ deviceData.tariff.currency | currency_to_symbol }}
             </span>
              <span *ngIf="isUKDevice()" class="is-pulled-right">
               {{ deviceData.tariff.currency | currency_to_symbol }}{{ getFee(FeeType.OFF_PEAK_WORK_FEE) | number: '1.2-3':currentLang
                }}
             </span>
            </ng-container>
          </p>

          <hr />
        </ng-container>


        <ng-container *ngIf="isOffPeakTariff && showOnOffPeakWindow">

          <p class="tariff__fee tariff__fee--work">
            {{ 'tariff.price.smart' | translate }} ({{
            getOffPeakPeriod().start | date: 'shortTime'
            }}-{{
            getOffPeakPeriod().end | date: 'shortTime'
            }})
            <sup *ngIf="!isUKDevice()"
              >{{ showMinimum() ? '1,2,3' : '1,2'
              }}{{ getFee(FeeType.OVERSTAY_FEE) ? ',6' : '' }},7</sup
            >
            <span *ngIf="!isUKDevice()" class="is-pulled-right">
              {{ getFee(FeeType.OFF_PEAK_WORK_FEE) | number: '1.2-3':currentLang
              }}{{ deviceData.tariff.currency | currency_to_symbol }}
            </span>
            <span *ngIf="isUKDevice()" class="is-pulled-right">
              {{ deviceData.tariff.currency | currency_to_symbol }}{{ getFee(FeeType.OFF_PEAK_WORK_FEE) | number: '1.2-3':currentLang
              }}
            </span>
          </p>

          <hr/>
        </ng-container>

        <ng-container *ngIf="isPeakTariff">

          <p class="tariff__fee tariff__fee--work">
            {{ 'tariff.price.peak' | translate }}
            <span *ngIf="isUKDevice()">
              ({{getPeakPeriod().start | date: 'shortTime'}}-{{getPeakPeriod().end | date: 'shortTime'}})
            </span>
            <sup *ngIf="!(isUKDevice())"
              >{{ showMinimum() ? '1,2,3' : '1,2'
              }}{{ getFee(FeeType.OVERSTAY_FEE) ? ',6' : '' }},8</sup
            >
            <span *ngIf="isUKDevice()" class="is-pulled-right">
              {{ deviceData.tariff.currency | currency_to_symbol }}{{
              getFee(FeeType.PEAK_WORK_FEE) | number: '1.2-3':currentLang
              }}
            </span>
            <span *ngIf="!isUKDevice()" class="is-pulled-right">
              {{ getFee(FeeType.PEAK_WORK_FEE) | number: '1.2-3':currentLang
              }}{{ deviceData.tariff.currency | currency_to_symbol }}
            </span>
          </p>

          <hr/>
        </ng-container>


        <ng-container *ngIf="getFee(FeeType.PROVIDER_PLUG_IN_FEE)">
          <p class="tariff__fee tariff__fee--provider-plugin">
            {{ 'tariff.providerPlugInFee' | translate }}
            <sup *ngIf="!isUKDevice()">{{ showMinimum() ? '2,4' : '1,3' }}</sup>
            <span *ngIf="isUKDevice()" class="is-pulled-right">
              {{ deviceData.tariff.currency | currency_to_symbol }}{{
                getFee(FeeType.PROVIDER_PLUG_IN_FEE)
                  | number: '1.2-3':currentLang
              }}
              </span>
              <span *ngIf="!isUKDevice()" class="is-pulled-right">
              {{
                getFee(FeeType.PROVIDER_PLUG_IN_FEE)
                  | number: '1.2-3':currentLang
                }}{{ deviceData.tariff.currency | currency_to_symbol }}
            </span>
          </p>

          <hr />
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isHoursTariff()">
        <p class="tariff__fee tariff__fee--hours-plugin">
          {{ 'welcome.plugInFee' | translate }}
          <span>{{ getFee(FeeType.PLUG_IN_FEE) }}</span>
          <span>{{ deviceData.tariff.currency | currency_to_symbol }}</span>
        </p>
        <p class="tariff__fee tariff__fee--hours-work">
          {{ 'welcome.pricePerHour' | translate }}
          <span>{{ getFee(FeeType.WORK_FEE) }}</span>
          <span>{{ deviceData.tariff.currency | currency_to_symbol }}</span>
        </p>

        <div class="hours-container">
          <div class="hour-container hour-container--left">
            <button
              id="welcome_BUTTON_setCurrentHours2"
              (click)="setCurrentHours(2)"
              class="button button--hours"
              [ngClass]="{ 'button--hours-active': currentHours === 2 }"
            >
              2H
            </button>
          </div>
          <div class="hour-container hour-container--middle">
            <button
              id="welcome_BUTTON_setCurrentHours3"
              (click)="setCurrentHours(4)"
              class="button button--hours"
              [ngClass]="{ 'button--hours-active': currentHours === 4 }"
            >
              4H
            </button>
          </div>
          <div class="hour-container hour-container--middle">
            <button
              id="welcome_BUTTON_setCurrentHours8"
              (click)="setCurrentHours(8)"
              class="button button--hours"
              [ngClass]="{ 'button--hours-active': currentHours === 8 }"
            >
              8H
            </button>
          </div>
          <div class="hour-container hour-container--right">
            <button
              id="welcome_BUTTON_setCurrentHours12"
              (click)="setCurrentHours(12)"
              class="button button--hours"
              [ngClass]="{ 'button--hours-active': currentHours === 12 }"
            >
              12H
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="scheduledChargingEnabled">
        <p>
          {{
            'scheduledCharging.generalInformation.pricePerKWH'
              | translate: scheduledChargingTime
          }}<span *ngIf="!isUKDevice()" class="is-pulled-right"
            >{{ getWorkFee() | number: '1.2-3':currentLang
            }}{{ deviceData.tariff.currency | currency_to_symbol }}</span
          >
          <span *ngIf="isUKDevice()" class="is-pulled-right">
            {{ deviceData.tariff.currency | currency_to_symbol }}{{ getWorkFee() | number: '1.2-3':currentLang
            }}</span>
        </p>
        <hr />
      </ng-container>

      <ng-container *ngIf="isOffPeakTariff">
        <div class="smart-charging-info-container">
          <p>Smart Charging is available on this charge point</p>
          <i
            id="sm-info"
            (click)="showSmartChargingInfoPopup()"
            class="smart-charging-info-container__sm_icon material-icons"
          >info</i
          >
        </div>
      </ng-container>

      <button
        id="welcome_BUTTON_proceedNextHT"
        *ngIf="isHoursTariff() && (!isSmartChargingView())"
        [disabled]="isDeviceDisabled() || isHoursDisabled()"
        (click)="proceedToNextComponent()"
        class="button"
      >
        {{ 'welcome.button3' | translate }}
      </button>
      <button
        id="welcome_BUTTON_proceedNextNHT"
        *ngIf="!isHoursTariff() && (!isSmartChargingView())"
        [disabled]="isDeviceDisabled()"
        (click)="proceedToNextComponent()"
        class="button"
      >
        {{ 'welcome.button.' + tariffPostfix | translate }}
      </button>
      <button
        id="welcome_BUTTON_proceedNextSC"
        *ngIf="
          scheduledChargingEnabled &&
          scheduledChargingTime?.startString !== ScheduledCharging.Now
        "
        [disabled]="isDeviceDisabled()"
        (click)="proceedToNextComponent(true)"
        class="button"
      >
        {{
          'scheduledCharging.scheduleChargeBtn'
            | translate: scheduledChargingTime
        }}
      </button>
    </ng-container>

    <ng-container *ngIf="deviceData === DEVICE_NOT_FOUND">
      <h1 class="title is-4">{{ 'not_found.title' | translate }}</h1>
      <h2 class="subtitle is-6">{{ 'not_found.subtitle' | translate }}</h2>
      <hr />
      <p>
        {{ 'not_found.text1' | translate }}
        <a
          id="welcome_A_redirectDeviceNotFound"
          [href]="'https://portal.mobilstrom.de/' + currentLang + '/map'"
          >{{ 'not_found.click' | translate }}</a
        >
        {{ 'not_found.text2' | translate }}
      </p>
      <hr />
    </ng-container>
  </div>


  <app-belgium-pilot></app-belgium-pilot>
  <ubi-pilot-project-banner></ubi-pilot-project-banner>

  <ng-container *ngIf="deviceData?.qrCodeId">
    <div class="container">
      <div class="tariff__information" *ngIf="deviceData.tariff.name && !isUKDevice()">
        <p *ngIf="showMinimum() && !isDEDevice()" class="small_p">
          <sup>1</sup>
          <span *ngIf="isDeviceCurrency('GBP')">{{
            'tariff.minGbp.' + tariffPostfix | translate
          }}</span>
          <span *ngIf="isDeviceCurrency('EUR')">{{
            'tariff.minEuro.' + tariffPostfix | translate
          }}</span>
          <span *ngIf="isDeviceCurrency('MOP')">{{
            'tariff.minMop.' + tariffPostfix | translate
          }}</span>
          <span *ngIf="!deviceData.tariff.currency">{{
            'tariff.minDefault.' + tariffPostfix | translate
          }}</span>
        </p>
        <p
          *ngIf="deviceData.tariff?.vat && currentLang !== 'fr'"
          class="small_p"
        >
          <sup>{{ showMinimum() ? '2' : '1' }}</sup>
          {{ 'tariff.*1.' + tariffPostfix | translate }}
          {{ deviceData.tariff?.vat | vat
          }}{{ 'tariff.*2.' + tariffPostfix | translate }}
        </p>
        <p
          *ngIf="deviceData.tariff?.vat && currentLang == 'fr'"
          class="small_p"
        >
          <sup>{{ showMinimum() ? '2' : '1' }}</sup>
          {{ deviceData.tariff?.vat | vat
          }}{{ 'tariff.*1.' + tariffPostfix | translate }}
        </p>
        <p class="small_p">
          <sup>{{ showMinimum() ? '3' : '2' }}</sup>
          {{ 'tariff.parkingFee' | translate }}
        </p>
        <p class="small_p" *ngIf="getFee(FeeType.PROVIDER_PLUG_IN_FEE)">
          <sup>{{ showMinimum() ? '4' : '3' }}</sup>
          {{ 'tariff.providerPlugInFeeSup1.' + tariffPostfix | translate }}
          {{ getFee(FeeType.PROVIDER_PLUG_IN_FEE) | number: '1.2-3':currentLang
          }}{{ deviceData.tariff.currency | currency_to_symbol }}
          {{ 'tariff.providerPlugInFeeSup2' | translate }}
        </p>
        <p class="small_p" *ngIf="getFee(FeeType.PROVIDER_OVERSTAY_FEE)">
          <sup>{{ showMinimum() ? '5' : '4' }}</sup>
          {{ 'tariff.providerOverstayFeeSup1.' + tariffPostfix | translate }}
          {{ getOverstayFreePeriod() }}
          {{ 'tariff.providerOverstayFeeSup2.' + tariffPostfix | translate }}
          {{ getOverstayFreePeriod()
          }}{{ 'tariff.providerOverstayFeeSup3.' + tariffPostfix | translate }}
          {{ getFee(FeeType.PROVIDER_OVERSTAY_FEE)
          }}{{ deviceData.tariff.currency | currency_to_symbol
          }}{{ 'tariff.providerOverstayFeeSup4.' + tariffPostfix | translate }}
        </p>
        <p class="small_p" *ngIf="(isOffPeakTariff && showOnOffPeakWindow)">
          <sup>7</sup>
          Smart charging is applied from {{
            getOffPeakPeriod().start | date: 'shortTime'
          }} to {{
            getOffPeakPeriod().end | date: 'shortTime'
          }}
        </p>
        <p class="small_p" *ngIf="(isPeakTariff)">
          <sup>8</sup>
          Peak price is applied from {{
            getPeakPeriod().start | date: 'shortTime'
          }} to {{
            getPeakPeriod().end | date: 'shortTime'
          }}
        </p>

        <p class="small_p" *ngIf="getFee(FeeType.OVERSTAY_FEE)">
          <sup>6</sup>
          {{
            'tariff.overstayFee.sideNote'
              | translate
                : {
                    fee:
                      getFee(FeeType.OVERSTAY_FEE) +
                      (deviceData.tariff.currency | currency_to_symbol)
                  }
          }}
          <span
            class="material-icons"
            style="vertical-align: middle; font-size: 14px; cursor: pointer"
            (click)="overstayFeeModal = true"
            >help_outline</span
          >
        </p>
        <p class="small_p" *ngIf="isUKDevice()">
          <sup>9</sup>
          <span>&nbsp;Some of our charge points may run at reduced power between 16:00 and 19:00.</span>
        </p>
      </div>
      <div class="superscript__information">
        <p *ngIf="isPeakTariff && isUKDevice()" class="small_p">Read more about charging with ubitricity in our
          <a
          id="redirectFAQ"
          [routerLink]="['/sidenav-info', 'qa']"
          title="FAQ"
        >FAQ</a
        > section.</p>
        <p *ngIf="isPeakTariff && isDEDevice()" class="small_p">Weitere Informationen über unseren smarten Tarif (16:00 - 19:00 Uhr) finden Sie <a id="welcome_DE_openNotificationCosts" href="http://info.ubitricity.com/costs/"  target="_blank">hier</a>. Während aller anderen Zeiten gilt unser Standard-Tarif.</p>
        <p *ngIf="isUKDevice()" class="small_p">Fees include 20% VAT. Local parking fees may apply.</p>
        <p *ngIf="isUKDevice()" class="small_p">Charging speeds may vary.</p>

        <p *ngIf="getFee(FeeType.OVERSTAY_FEE)" class="small_p">{{
          'tariff.overstayFee.sideNote'
            | translate
              : {
                  fee:
                    getFee(FeeType.OVERSTAY_FEE) +
                    (deviceData.tariff.currency | currency_to_symbol)
                }
          }}</p>
      </div>
    </div>
  </ng-container>

  <div class="modal" [ngClass]="{ 'is-active': showNotificationPopup }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <h2 class="mb-5 bold" [innerHTML]="'notification.popup.title' | translate"></h2>
      <p class="mb-3" [innerHTML]="'notification.popup.content' | translate | translateCut: 0">
      </p>
      <br />
      <div class="action">
        <button
          id="WELCOME_BUTTON_notificationPopUpClose"
          (click)="hideNotificationPopup()"
          class="button is-rounded is-medium"
        >
          {{ 'notification.popup.close' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal overstay_fee_modal"
    [ngClass]="{ 'is-active': overstayFeeModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <h2 class="subtitle is-4">
        {{ 'tariff.overstayFee.popUpTitle' | translate }}
      </h2>
      <p
        *ngIf="deviceData"
        [innerHTML]="
          'tariff.overstayFee.popUpText'
            | translateHtml
              : {
                  freePeriod: getOverstayFreePeriod(FeeType.OVERSTAY_FEE),
                  faqLink:
                    '<a href=/sidenav-info/qa>' +
                    ('tariff.overstayFee.faqLinkText' | translate) +
                    '</a>'
                }
        "
      ></p>
      <div style="margin-top: 10px; text-align: center">
        <button
          (click)="overstayFeeModal = false"
          class="button is-rounded is-medium"
          aria-label="close"
        >
          {{ 'survey.popup.back' | translate }}
        </button>
      </div>
      <button
        class="modal-close is-medium overstay_fee_modal_close"
        (click)="overstayFeeModal = false"
        aria-label="close"
      ></button>
    </div>
  </div>
</section>

<div *ngIf="showOffPeakMessage" class="modal overstay_fee_modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <h2 class="subtitle is-4">{{ 'tariff.offPeak.popUpTitle' | translate }}</h2>
    <div id="welcome_DIV_offPeakPopUpTitle" [innerHTML]="'tariff.offPeak.popUpText' | translate"></div>

    <button
      id="welcome_BUTTON_offPeakPopUpContinue"
      class="button is-rounded is-fullwidth"
      (click)="hideOffPeakInfo()"
      aria-label="close"
    >
      {{ 'tariff.offPeak.continue' | translate }}
    </button>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="muteOffPeakMessage" />
      {{ 'tariff.offPeak.mute' | translate }}
    </label>
  </div>
</div>

<div *ngIf="showSkipPeakPopup" class="modal overstay_fee_modal"
     [ngClass]="{ 'is-active': showSkipPeakPopup }">
  <div class="modal-background"></div>
  <div class="modal-content skip-peak-fee-modal-content">
    <h2 class="subtitle is-marginless mb-3 has-text-weight-bold">{{ 'tariff.skip-peak-popup.title' | translate }}</h2>
    <div class="mb-3 has-text-justified"
         [innerHTML]="'tariff.skip-peak-popup.standardPriceInfo' | translateHtml : {
         standardPrice: getFeeInMinorUnits(FeeType.WORK_FEE) | number:'0.0':currentLang
    }"></div>
    <div class="mb-3 has-text-justified"
         [innerHTML]="'tariff.skip-peak-popup.peakPriceInfo' | translateHtml : {
         peakPrice: getFeeInMinorUnits(FeeType.PEAK_WORK_FEE) | number:'0.0':currentLang,
         peakStartTime: getPeakPeriod().start | date: 'shortTime',
         peakEndTime: getPeakPeriod().end | date: 'shortTime'
    }"></div>
    <div class="mb-3 has-text-justified"
         [innerHTML]="'tariff.skip-peak-popup.text' | translateHtml : {
         peakStartTime: getPeakPeriod().start | date: 'shortTime',
         peakEndTime: getPeakPeriod().end | date: 'shortTime'
    }"></div>
    <div class="mb-3" [innerHTML]="'tariff.skip-peak-popup.info' | translate"></div>
    <div class="mb-5 bold" [innerHTML]="'tariff.skip-peak-popup.question' | translate"></div>

    <button
      id="welcome_BUTTON_peakPopUpContinueWithPeak"
      class="button skip-peak-fee-no-button is-pulled-left"
      aria-label="close"
      (click)="showPeakChargingConfirmationPopup()"
    >
      {{ 'tariff.skip-peak-popup.no' | translate }}
    </button>

    <button
      id="welcome_BUTTON_peakPopUpSmartCharging"
      class="button skip-peak-fee-yes-button is-pulled-right"
      (click)="proceedToNextComponentWithSmartCharging(true)"
      aria-label="close"
    >
      {{ 'tariff.skip-peak-popup.yes' | translate }}
    </button>

  </div>
</div>

<div *ngIf="showPeakConfirmationPopup" class="modal overstay_fee_modal"
     [ngClass]="{ 'is-active': showPeakConfirmationPopup }">
  <div class="modal-background"></div>
  <div class="modal-content skip-peak-fee-modal-content">
    <h2 class="subtitle is-marginless mb-3 has-text-weight-bold">
      {{ 'tariff.peak-confirmation-popup.title' | translate }}
    </h2>
    <div class="mb-3 has-text-justified"
         [innerHTML]="'tariff.peak-confirmation-popup.text' | translateHtml : {
         peakStartTime: getPeakPeriod().start | date: 'shortTime',
         peakEndTime: getPeakPeriod().end | date: 'shortTime',
         peakPrice: getFeeInMinorUnits(FeeType.PEAK_WORK_FEE) | number: '0.0':currentLang
         }"></div>
    <div class="mb-5 bold" [innerHTML]="'tariff.peak-confirmation-popup.question' | translate"></div>

    <button
      id="welcome_BUTTON_peakConfirmationPopUpContinueWithPeak"
      class="button skip-peak-fee-no-button is-pulled-left"
      aria-label="close"
      (click)="proceedToNextComponentWithSmartCharging(false)"
    >
      {{ 'tariff.peak-confirmation-popup.no' | translate }}
    </button>

    <button
      id="welcome_BUTTON_peakConfirmationPopUpSmartCharging"
      class="button skip-peak-fee-yes-button is-pulled-right"
      (click)="proceedToNextComponentWithSmartCharging(true)"
      aria-label="close"
    >
      {{ 'tariff.peak-confirmation-popup.yes' | translate }}
    </button>

  </div>
</div>

<div *ngIf="showSmartChargingInfo" class="modal overstay_fee_modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <h2 class="subtitle is-4">{{ 'tariff.offPeak.popUpTitle' | translate }}</h2>
    <div id="welcome_DIV_smPopUpTitle">
      Smart Charging schedules your charge when the off peak tariff hours begin.
      Choosing the Smart Charging saves you money on your charging session.
      You can find more information in our <a target='_blank' href='/sidenav-info/qa'>FAQs</a>.
    </div>

    <button
      id="welcome_BUTTON_smPopUpContinue"
      class="button is-rounded is-fullwidth"
      (click)="closeSmartChargingInfoPopup()"
      aria-label="close"
    >
      {{ 'notification.popup.close' | translate }}
    </button>
  </div>
</div>
