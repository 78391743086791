import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmartChargingService {
  private smartChargingEnabled: boolean;

  isSmartChargingEnabled() {
    return this.smartChargingEnabled;
  }

  setSmartChargingEnabled() {
    this.smartChargingEnabled = true;
  }

  setSmartChargingDisabled() {
    this.smartChargingEnabled = false;
  }

  setSmartCharging(enabled: boolean) {
    this.smartChargingEnabled = enabled;
  }

  constructor() {}
}
