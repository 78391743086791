import { Injectable } from '@angular/core';

import * as storage from '../storage/storage';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  private COOKIE_NAME: string = 'ubi-da-cookies';

  private accepted: boolean = false;

  constructor() {
    this.checkLocalStorage();
  }

  public acceptCookies(): void {
    this.accepted = true;
    storage.local.setItem(this.COOKIE_NAME, `${this.accepted}`);
  }

  public canUseCookies(): boolean {
    return this.accepted;
  }

  private checkLocalStorage(): void {
    this.accepted = storage.local.getItem(this.COOKIE_NAME) === 'true';
  }
}
