import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractView } from '../abstract-view/AbstractView.component';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDataService } from '../../services/device-data/device-data.service';
import {
  MasterContext,
  MasterContextPrefix,
} from '../../config/masterContext.config';

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrls: ['./invalid.component.scss'],
})
export class InvalidComponent extends AbstractView {
  public masterContext;
  public masterContextPrefix;
  constructor(
    private _router: Router,
    private service: DeviceDataService,
    private translate: TranslateService
  ) {
    super(_router, service, translate);
    this.masterContext = MasterContext();
    this.masterContextPrefix = MasterContextPrefix();
  }

  search(id) {
    this._router.navigate([id]);
  }
}
