// DTO for device

export class Device {
  chargePointId: string;
  dataProtectionLink: string;
  email: string;
  power: number;
  address: Address;
  id: string;
  provider: string;
  qrCodeId: string;
  evseOcppIndex: number;
  tariff: any;
  termsAndConditionsLink: string;
  stripeAccount: string | null;
  smartChargingSupported?: boolean;
  canOmitStatusCheckPage?: boolean;
  timezone: string;
  transactionIdTag: string;

  constructor() {
    this.tariff = new NewTariff();
  }
}
export class Address {
  countryCode: CountryCode;
}

export enum CountryCode {
  GB = 'GB',
  DE = 'DE',
  AT = 'AT',
  BE = 'BE',
  FR = 'FR',
  PL = 'PL',
  ES = 'ES',
  IE = 'IE',
  CN = 'CN',
  MO = 'MO',
}

export enum TariffType {
  FLAT_RATE = 'FLAT_RATE',
  EVENT = 'EVENT',
  KWH = 'KWH',
  HOURS = 'HOURS',
}

export enum State {
  PLUGGED_IN = 'PLUGGED_IN',
  AVAILABLE = 'AVAILABLE',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  OCCUPIED = 'OCCUPIED',
}

export enum ParkingStatus {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
}

export class DirectAccessObservationDto {
  actualAt: number;
  directAccessState: State;
  parkingStatus: ParkingStatus;
}

export class NewTariff {
  createdOn: number;
  currency: string;
  fees: Fee[];
  id: string;
  name: string;
  vat: number;
  preAuthAmount: number = 20;
}

export class Fee {
  billingUnit: TariffType;
  description: string;
  directAccessCharging: boolean;
  freeInterval: number;
  mobileMeterCharging: boolean;
  payoutToProviderPerUnit: number;
  penaltyInterval: number;
  type: FeeType;
  userPricePerUnit: number;
  smartChargingOptionAvailableFrom?: number[];
  offPeakPeriodStart?: number[];
  offPeakPeriodEnd?: number[];
  peakPeriodStart?: number[];
  peakPeriodEnd?: number[];
}

export enum FeeType {
  WORK_FEE = 'WORK_FEE',
  PLUG_IN_FEE = 'PLUG_IN_FEE',
  OVERSTAY_FEE = 'OVERSTAY_FEE',
  PROVIDER_WORK_FEE = 'PROVIDER_WORK_FEE',
  PROVIDER_PLUG_IN_FEE = 'PROVIDER_PLUG_IN_FEE',
  PROVIDER_OVERSTAY_FEE = 'PROVIDER_OVERSTAY_FEE',
  OFF_PEAK_WORK_FEE = 'OFF_PEAK_WORK_FEE',
  PEAK_WORK_FEE = 'PEAK_WORK_FEE'
}
