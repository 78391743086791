import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './core/components/welcome/welcome.component';
import { InvalidComponent } from './core/components/invalid/invalid.component';

const routes: Routes = [
  { path: '', component: InvalidComponent },
  { path: ':id', component: WelcomeComponent },
  { path: ':id/:tag', component: WelcomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
