import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CurrentHash, MasterContext } from '../../config/masterContext.config';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `./assets/i18n/${MasterContext()}/`,
    '.json?v=' + CurrentHash()
  );
}
