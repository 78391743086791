import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { Device, State, TariffType } from '../../dto/device';
import { DeviceDataService } from '../../services/device-data/device-data.service';

@Injectable()
export abstract class AbstractView implements OnInit, OnDestroy {
  public deviceData: Device;
  public deviceState: State;
  public qrCode: string;
  public tariffPostfix: string = 'default';

  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private _service: DeviceDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._service.deviceData.subscribe((data: Device) => {
      this.deviceData = data;
      this.tariffPostfix =
        (data && data.tariff && data.tariff.tariffType) || 'default';
    });

    this.qrCode = this._service.getQrCode();

    this._service.deviceState.subscribe((state) => {
      this.deviceState = state.directAccessState;
      if (state.directAccessState === State.OUT_OF_SERVICE) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public setCurrentComponent(component) {
    this._service.setCurrentComponent(component);
  }

  public isHoursTariff(): boolean {
    return (
      this.deviceData &&
      this.deviceData.tariff &&
      this.deviceData.tariff.tariffType === TariffType.HOURS
    );
  }

  public isDeviceDisabled(): boolean {
    return !this.isDeviceAvailable() && !this.isDevicePluggedIn();
  }

  public isDeviceAvailable(): boolean {
    return this.deviceState === State.AVAILABLE;
  }

  public isDevicePluggedIn(): boolean {
    return this.deviceState === State.PLUGGED_IN;
  }

  public navigateTo(destination): void {
    this._service.setCurrentComponent(destination);
    this.router.navigate([`${destination}`, this.deviceData.transactionIdTag]);
  }
}
