import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'vat' })
export class VatPipe implements PipeTransform {
  transform(value) {
    if (typeof value !== 'string') value = value.toString();
    value = value.split('.');
    let vat;
    value[1].length == 1 ? (vat = value[1] + '0') : (vat = value[1]);
    return vat;
  }
}
