<ng-container *ngIf="show()">
  <div class="container mt-5">
    <figure class="image">
      <img
        src="{{filename}}"
        width="100%"
        alt="Pilot testing with Sibelga"
        class="is-pulled-right"
      />
    </figure>
  </div>
</ng-container>
