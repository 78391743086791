import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BelgiumPilotComponent } from '../../components/belgium-pilot/belgium-pilot';
import { PilotProjectBannerComponent } from '../../components/pilot.project.banner/pilot.project.banner.component';
import { CurrencyToSymbol } from '../../pipes/currency.pipe';
import { TranslateCut } from '../../pipes/translate-cut.pipe';
import { TranslateHtmlPipe } from '../../pipes/translate-html-params.pipe';
import { VatPipe } from '../../pipes/vat.pipe';

@NgModule({
  declarations: [
    BelgiumPilotComponent,
    PilotProjectBannerComponent,
    TranslateCut,
    TranslateHtmlPipe,
    CurrencyToSymbol,
    VatPipe,
  ],
  imports: [CommonModule, FormsModule, TranslateModule],
  exports: [
    CommonModule,
    FormsModule,
    BelgiumPilotComponent,
    PilotProjectBannerComponent,
    TranslateModule,
    TranslateCut,
    TranslateHtmlPipe,
    CurrencyToSymbol,
    VatPipe,
  ],
})
export class AppCommonModule {
}
