import { environment } from 'src/environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { masterContextSupportedLangs } from './core/config/masterContext.config';
import { CookiesService } from './core/services/cookies/cookies.service';
import { GtmService } from './core/services/gtm/gtm.service';
import { SplitService } from './core/services/split/split.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private PROCESS_STEPS: string[] = [
    'stripe',
    'payment',
    'welcome',
    'initialization',
    'tariff',
    'charging',
    'smart-cable',
  ];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private splitService: SplitService,
    private cookiesService: CookiesService
  ) {
    const supportedLangs = masterContextSupportedLangs();
    translate.addLangs(supportedLangs);
    translate.setDefaultLang(supportedLangs[0]);

    if (supportedLangs.includes(translate.getBrowserLang())) {
      translate.use(translate.getBrowserLang());
    } else {
      translate.use(translate.getDefaultLang());
    }

    // this.initAnalytics();
  }

  ngOnInit() {
    window.onbeforeunload = (event) => {
      if (!window['forceReload'] && environment.production) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
      }
    };
  }

  private initAnalytics(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        GtmService.callGtag('redirect', { page_path: event.urlAfterRedirects });
      }
    });
  }

  @HostListener('window:ucEvent', ['$event'])
  public onClick(e: any): void {
    if (e.detail && e.detail.event === 'consent_status') {
      window['ga-disable-UA-131598989-1'] = !e.detail['Google Analytics'];
    }
  }

  @HostListener('click', ['$event.target'])
  public onClickListener(e: any): void {
    if (e.id && e.id.split('_').length > 2) {
      GtmService.trackClick(e.id);
    }
  }
}
