import { Component, OnInit } from '@angular/core';
import { DeviceDataService } from '../../services/device-data/device-data.service';
import { CountryCode } from '../../dto/device';

@Component({
  selector: 'app-belgium-pilot',
  templateUrl: './belgium-pilot.html',
  styleUrls: ['./belgium-pilot.scss'],
})
export class BelgiumPilotComponent implements OnInit {
  private routes = ['charging'];

  private belgium: boolean = false;
  private filename: string = '';

  constructor(private deviceDataService: DeviceDataService) {}

  ngOnInit() {
    this.deviceDataService.deviceData.subscribe((deviceData) => {
      this.belgium = deviceData && deviceData.address.countryCode == CountryCode.BE;
    });
    const banner = this.bannerByRoute();
    this.filename = banner ? `assets/images/${banner}` : '';
  }

  bannerByRoute(): string {
    const index = this.routes.findIndex((r) =>
      window.location.href.includes(r)
    );
    return index > -1 ? `sibelga-thank-you.png` : `sibelga.png`;
  }

  show(): boolean {
    return this.belgium && this.filename !== '';
  }
}
