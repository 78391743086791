import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateHtml',
})
export class TranslateHtmlPipe extends TranslatePipe implements PipeTransform {
  constructor(
    translate: TranslateService,
    _ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    super(translate, _ref);
  }

  transform(query: string, ...args: any[]): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      super.transform(query, ...args)
    );
  }
}
