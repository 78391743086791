import { Injectable } from '@angular/core';

import { DeviceDataService } from '../device-data/device-data.service';

import { Device } from '../../dto/device';

import { SplitService } from '../split/split.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelperService {
  public deviceData: Device;

  constructor(
    private deviceDataService: DeviceDataService,
    private splitService: SplitService
  ) {
    this.initSubscriptions();
  }

  public isSkipPayment(
    countryCode: string = this.deviceData.address.countryCode,
    ssoId: string = this.deviceData.chargePointId
  ): boolean {
    return this.splitService.isSkipPayment(countryCode, ssoId);
  }

  private initSubscriptions(): void {
    this.deviceDataService.deviceData.subscribe((data: Device) => {
      this.deviceData = data;
    });
  }
}
