import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { session } from './core/services/storage/storage';

@Injectable()
export class AppConfigService {
  constructor(private _http: HttpClient) {}

  public loadConfig(): Promise<any> {
    this.setContext();

    if (environment.production) {
      return this._http
        .get('/assets/config/appConfig.json')
        .toPromise()
        .then((config) => {
          Object.keys(config).map((key) => {
            if (!config[key].includes('$')) {
              environment[key] = config[key];
            }
          });
        })
        .catch((error) => console.error(error));
    } else {
      console.log('STRIPE_KEY_DE:' + environment.STRIPE_KEY_DE);
      console.log('STRIPE_KEY_GB:' + environment.STRIPE_KEY_GB);
      console.log('STRIPE_KEY_FR:' + environment.STRIPE_KEY_FR);
    }
  }

  private setContext(): void {
    const evseId = window.location.pathname.match(
      /(?<country>[A-Za-z]{2})[*]?(?<company>[A-Za-z0-9]{3})[*]?E(?<id>[A-Za-z0-9*]{1,30})/
    );

    if (evseId?.groups.country) {
      session.setItem('DA_CONTEXT', evseId.groups.country.toUpperCase());
    }
  }
}
