import { Component, OnInit } from '@angular/core';
import { SplitService } from '../../services/split/split.service';
import { DeviceDataService } from '../../services/device-data/device-data.service';
import { Device } from '../../dto/device';

@Component({
  selector: 'ubi-pilot-project-banner',
  templateUrl: './pilot.project.banner.component.html',
  styleUrls: ['./pilot.project.banner.component.scss'],
})
export class PilotProjectBannerComponent implements OnInit {
  private deviceData: Device;

  constructor(
    private splitService: SplitService,
    private deviceDataService: DeviceDataService
  ) {}

  ngOnInit(): void {
    this.deviceDataService.deviceData.subscribe(
      (deviceData) => (this.deviceData = deviceData)
    );
  }

  isBannerEnabled(): boolean {
    return this.splitService.isBannerEnabled(this.deviceData.chargePointId);
  }
}
