import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency_to_symbol' })
export class CurrencyToSymbol implements PipeTransform {
  transform(currency: string): string {
    if (currency === 'EUR') {
      return '€';
    } else if (currency === 'GBP') {
      return '£';
    } else if (currency === 'MOP') {
      return 'MOP$';
    } else {
      return currency;
    }
  }
}
