export enum ScheduledCharging {
  Now = 'NOW',
  Later = 'LATER',
}

export class ScheduledChargingTimeSource {
  startTime: Date;
  startTimeString: string;
  endTime: Date;
  endTimeString: string;
  startString: ScheduledCharging;
}

export const ScheduledChargingTimeDisplayConfig: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};
