import {Injectable} from '@angular/core';
import {SplitFactory} from '@splitsoftware/splitio';
import {fromEvent, BehaviorSubject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CountryCode} from '../../dto/device';
import {Attributes} from '@splitsoftware/splitio/types/splitio';

@Injectable({
  providedIn: 'root',
})
export class SplitService {
  private splitio: SplitIO.ISDK;
  private splitClient: SplitIO.IClient;
  public isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.initSdk();
  }

  public initSdk(): void {
    this.splitio = SplitFactory({
      core: {
        authorizationKey: environment.SPLIT_KEY,
        key: 'DirectAccessFrontend',
      },
    });

    this.splitClient = this.splitio.client();
    this.verifyReady();
  }

  private verifyReady(): void {
    let that = this;
    const isReadyEvent = fromEvent(
      this.splitClient,
      this.splitClient.Event.SDK_READY
    );
    const subscription = isReadyEvent.subscribe({
      next() {
        that.isReady.next(true);
      },
      error(err) {
        that.isReady.next(false);
      },
    });
  }

  private getTreatment(treatment: string, attributes?: Attributes): boolean {
    let treatmentValue: string = this.splitClient.getTreatment(
      treatment,
      attributes
    );
    switch (treatmentValue) {
      case 'enabled':
        return true;
      case 'disabled':
        return false;
      default:
        return false;
    }
  }

  public isMobileAppResearchSurveyEnabled(): boolean {
    return this.getTreatment('direct_access_mobile_app_survey');
  }

  public isBannerEnabled(id: string): boolean {
    return this.getTreatment('direct_access_pilot_project_banner', {
      ssoId: id,
    });
  }

  public isSkipPayment(countryCode: string, id: string): boolean {
    return this.getTreatment('direct_access_skip_payment', {
      countryCode: countryCode,
      ssoId: id,
    });
  }

  public isDisplayParkingStatusEnabled(id: string): boolean {
    return this.getTreatment('direct_access_display_parking_status', {
      ssoId: id,
    });
  }

  public isMobileWalletPaymentSystemEnabled(countryCode: CountryCode): boolean {
    return this.getTreatment('direct_access_mobile_wallet_payment_system', {
      countryCode: countryCode,
    });
  }

  public isScheduledChargingEnabled(countryCode: CountryCode, ssoId: string) {
    return this.getTreatment('direct_access_scheduled_charging', {
      countryCode,
      ssoId,
    });
  }

  public isNotificationPopupEnabled(cppContractNumber: string) {
    return this.getTreatment('direct_access_notification_popup', {
      cppContractNumber,
    });
  }

  private attrs(countryCode: CountryCode) {
    return {
      countryCode: countryCode ? countryCode : 'PL',
      chargingDay: new Date().getTime(),
    };
  }
}
