export const environment = {
  SENTRY_ENV: '',
  production: true,
  BASE_URL: '',
  PATH: '/direct-access/services/',
  checkInterval: 5000,
  STRIPE_KEY_DE: '',
  STRIPE_KEY_GB: '',
  STRIPE_KEY_FR: '',
  SPLIT_KEY: '',
};
