<ng-container *ngIf="isBannerEnabled()">
  <div class="container mt-5">
    <figure class="image">
      <img
        src="assets/images/20211012-banner-IE.png"
        width="100%"
        alt="direct access banner"
        class="is-pulled-right"
      />
    </figure>
  </div>
</ng-container>
