<div class="header">
  <app-sidenav
    [openSideNavBar]="isSideNavOpen"
    (onMenuClose)="onMenuClose()"
  ></app-sidenav>
  <i
    id="openSideNav"
    (click)="openNav()"
    class="header__icon header__icon--menu material-icons"
    >dehaze</i
  >
  <i
    id="openFAQ"
    [routerLink]="['/sidenav-info', 'qa']"
    class="header__icon header__icon--faq material-icons"
    title="FAQ"
    >help_outline</i
  >
  <object
    class="header__logo is-pulled-right"
    data="assets/images/ubitricity_logo_DA_200px.svg"
    type="image/svg+xml"
  >
    <img src="assets/images/ubitricity_Logo_200px.png" alt="logo" />
  </object>
</div>
