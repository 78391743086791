import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor() {}

  static trackModal(body: {
    view: string;
    target: string;
    value: string;
  }): void {
    this.callGtag('openModal', {
      event_label: body.view,
      event_target: body.target,
      value: body.value,
    });
  }

  static trackClick(id: string): void {
    const [view, element, action] = id.split('_');
    this.callGtag(action, {
      event_label: view,
      event_target: element,
      value: action,
    });
  }

  static callGtag(action: string, body): void {
    // gtag('event', action, body);
  }

  static trackConversion(
    view: string,
    conversionEvent: 'CARD' | 'MOBILE'
  ): void {
    const action =
      conversionEvent === 'CARD' ? 'cardPurchase' : 'mobileWalletPurchase';
    this.callGtag(action, {
      event_label: view,
      event_target: 'FUNCTION',
      value: action,
    });
  }
}
