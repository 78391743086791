import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDataService } from '../services/device-data/device-data.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceSessionExists implements CanActivate {
  constructor(
    private router: Router,
    private deviceService: DeviceDataService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.deviceService.deviceData.pipe(
      map((deviceData) => {
        const canAccess = !!(deviceData && deviceData.chargePointId);
        if (!canAccess || this.deviceService.processEnded) {
          this.router.navigate(['']);
        }
        return canAccess;
      })
    );
  }
}
