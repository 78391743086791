<section class="section">
  <div class="container">
    <div *ngIf="deviceState !== 'OUT_OF_SERVICE'">
      <h1 class="title is-4">{{ 'invalid.title' | translate }}</h1>
      <h2 class="subtitle is-6">{{ 'invalid.subtitle' | translate }}</h2>

      <p>{{ 'invalid.text1' | translate }}</p>
      <div style="margin-top: 2rem; position: relative">
        <input
          name="invalid_search"
          id="invalid_INPUT_search"
          type="text"
          [ngClass]="masterContext"
          #qrid
          placeholder="{{ 'invalid.placeholder' | translate }}"
        />
        <span class="placeholder">{{ masterContextPrefix }}/</span>
        <button
          id="invalid_BUTTON_search"
          (click)="search(qrid.value)"
          style="margin-top: 15px"
          class="button"
        >
          {{ 'invalid.button' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="deviceState == 'OUT_OF_SERVICE'">
      <h1 class="title is-4">{{ 'technical_problem.title' | translate }}</h1>
      <h2 class="subtitle is-6">
        {{ 'technical_problem.subtitle' | translate }}
      </h2>
      <br />
      <hr />
      <p>
        {{ 'technical_problem.click' | translate }}
        <a id="invalid_A_redirectMapsProblem" href="http://maps.google.com">{{
          'technical_problem.here' | translate
        }}</a>
        {{ 'technical_problem.text' | translate }}
      </p>
      <hr />
    </div>
  </div>

  <app-belgium-pilot></app-belgium-pilot>
  <ubi-pilot-project-banner></ubi-pilot-project-banner>
</section>
